.fieldsInputContainer {
  padding: var(--space-24);
}

.outComeContainer {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-12);
}

.outComeHeader {
  font-size: var(--font-size-16);
  color: var(--text-color-title);
  font-weight: var(--font-weight-500);
}

.title_1 {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-8);
}
